/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

p {
  @apply mb-2;
}

@font-face {
  font-family: IndustryInc-Base;
  src: url('/fonts/3A2884_0_0.woff2') format('woff2');
  src: url('/fonts/3A2884_0_0.woff') format('woff');
  font-display: swap;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

h1,
h2,
h3,
h4 {
  @apply mb-2 leading-tight;
  font-family: 'IndustryInc-Base', sans-serif;
  font-weight: normal;
  font-style: normal;
}

.industry-font {
  font-family: 'IndustryInc-Base', sans-serif;
}

@screen lg {
  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-4xl;
  }

  h3 {
    @apply text-3xl;
  }
}
h1 {
  @apply text-4xl;
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-2xl;
}

a {
  @apply text-primary;
}

ul {
  list-style-type: disc;
  padding-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

li {
  display: list-item;
}

@media (max-width: 768px) {
  section {
    padding-top: 10%;
    padding-bottom: 10%;
  }
}

@media (min-width: 769px) {
  section {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

@media (min-width: 960px) {
  .responsive-bg-image-header img {
    object-position: 0 -60px;
  }
}
/* Inline Header Styles for Targeting iPhone SE and iPad Mini resolutions */
@media (max-width: 376px) and (max-height: 668px) {
  section .inline-wizard {
    margin-top: 8.5em;
  }

  section .inline-wizard .title-wizard {
    margin-bottom: 0.25em;
  }
}

/*.custom-chevron {*/
/*  position: relative;*/
/*}*/
/*.custom-chevron::before {*/
/*  display: inline-block;*/
/*  font-style: normal;*/
/*  font-variant: normal;*/
/*  text-rendering: auto;*/
/*  -webkit-font-smoothing: antialiased;*/
/*}*/
/*.custom-chevron:after {*/
/*  content: '\f107';*/
/*  font: normal normal normal 17px/1 'Font Awesome 5 Free';*/
/*  color: #000;*/
/*  right: 11px;*/
/*  top: 6px;*/
/*  height: 34px;*/
/*  position: absolute;*/
/*  pointer-events: none;*/
/*  font-weight: 900;*/
/*}*/

/*.custom-chevron select {*/
/*  -webkit-appearance: none;*/
/*  -moz-appearance: none;*/
/*  appearance: none;*/
/*  !* Add some styling *!*/

/*  display: block;*/
/*  width: 100%;*/
/*  float: right;*/
/*  color: #333;*/
/*  -ms-word-break: normal;*/
/*  word-break: normal;*/
/*}*/
/*!* remove select button IE11 *!*/
/*select::-ms-expand {*/
/*  display: none;*/
/*}*/

.form-control-height {
  height: calc(1.5em + 0.75rem + 2px);
}

@layer utilities {
  .style-outline-primary {
    border-color: #f8ae9a;
    box-shadow: 0 0 0 0.2rem rgba(240, 78, 35, 0.25);
  }
}

.full-width-image {
  width: 100%;
  min-height: 60vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
@layer components {
  .title {
    @apply font-bold text-4xl;
  }
  .top-section {
    @apply text-white;
  }
  .description {
    @apply font-normal text-nugrey text-lg pb-4;
  }
  .subheading {
    @apply text-gray-200 description;
  }
}

.style-outline-primary {
  border-color: #f8ae9a;
  box-shadow: 0 0 0 0.2rem rgba(240, 78, 35, 0.25);
}

.max-w-xxs {
  max-width: 10rem;
}

/* MEGA MENU NAVIGATION */
.wrapper {
  display: inline-block;
  position: relative;
  height: 40px;
  margin: 0 14px 0 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrapper ul {
  margin: 0;
  padding: 0;
}
.wrapper ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.trigger {
  display: block;
  position: relative;
  line-height: 40px;
  color: #ffffff;
  border-radius: 3px;
}
.trigger::before,
.trigger::after {
  /* this is the arrow inside the dropdown trigger*/
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #777;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: width 0.3s, -webkit-transform 0.3s;
  -moz-transition: width 0.3s, -moz-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
}
.trigger.is-active::before,
.trigger.is-active::after {
  background: rgb(240, 78, 35);
}
.trigger::before {
  right: -9px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.trigger::after {
  right: -14px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    height: 40px;
    margin: 1em 14px 0 0;
  }
  .trigger.is-active::before,
  .trigger.is-active::after {
    /* transform the arrow into a 'X' */
    width: 14px;
  }
  .trigger.is-active::before {
    /* transform the arrow into a 'X' */
    -webkit-transform: translateX(5px) rotate(-45deg);
    -moz-transform: translateX(5px) rotate(-45deg);
    -ms-transform: translateX(5px) rotate(-45deg);
    -o-transform: translateX(5px) rotate(-45deg);
    transform: translateX(5px) rotate(-45deg);
  }
  .trigger.is-active::after {
    /* transform the arrow into a 'X' */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
.dropdown-menu .nested-dropdown li a {
  font-weight: normal;
}
.dropdown-menu .group-title a,
.service-group a,
.service-group .nested-dropdown a {
  height: 30px;
  line-height: 30px;
}
@media only screen and (max-width: 1023px) {
  .dropdown-menu.is-active {
    z-index: 999;
    position: fixed;
  }

  .title-button {
    display: block;
    padding-left: 15px;
  }
  .react-portal-wrapper {
    position: relative;
  }
  .dropdown-menu.is-active .service-group .nested-dropdown {
    padding-top: 0;
    background: white;
  }
  .dropdown-menu .group-title a {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  .service-group .go-back {
    margin-bottom: 15px;
  }
  .service-group .nested-dropdown .go-back a {
    font-weight: bold;
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1023px) {
  .service-group .go-back a {
    display: none;
  }
}
@media only screen and (min-width: 1024px) {
  .dropdown-menu .group-title a,
  .service-group a,
  .service-group .nested-dropdown a {
    height: 30px;
    line-height: 30px;
  }
  .dropdown-menu .group-title .title-link {
    height: 30px;
  }
  .dropdown-menu .group-title a.title-link {
    cursor: default;
  }
  .dropdown-menu .group-title a.title-link:hover {
    color: rgb(240, 78, 35);
  }
}

.dropdown-menu .group-title a,
.service-group a,
.service-group .nested-dropdown a,
.service-group .cd-divider {
  padding: 0 20px;
}

.dropdown-menu {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 815px;
  background-color: #fff;
  color: #222;
  visibility: hidden;
  padding-bottom: 1em;
  /* Force Hardware Acceleration */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
  -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
  transition: transform 0.5s 0s, visibility 0s 0.5s;
}
.dropdown-menu li {
  list-style: none;
}
.dropdown-menu ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu .group-title a {
  /* dropdown title - mobile version only */
  position: relative;
  z-index: 1;
  color: rgb(240 78 35);
  background-color: #ffffff;
  /* border-bottom: 1px solid #f8f8f8; */
}
.dropdown-menu .close {
  /* 'X' close icon - mobile version only */
  position: absolute;
  z-index: 200;
  right: 6px;
  top: -2px;
  height: 36px;
  width: 36px;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}
.dropdown-menu .close::after,
.dropdown-menu .close::before {
  /* this is the 'X' icon */
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 20px;
  width: 2px;
  background-color: #222222;
}
.dropdown-menu .close::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.dropdown-menu .close::before {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.dropdown-menu.is-active {
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0s;
  transition: transform 0.5s 0s, visibility 0s 0s;
}
.navbar-wrapper {
  z-index: 100;
}
@media only screen and (min-width: 768px) {
  .dropdown-menu .close {
    top: 5px;
  }
}
/* // 963 to 1170px  we have an offset issue  */
@media only screen and (min-width: 1024px) {
  .dropdown-menu {
    position: fixed;
    z-index: 99;
    top: 65px; /* calc(7% - -15px); */
    left: calc(38% - 803px / 2);
    /* reset style*/
    height: auto;
    width: 815px; /* was auto */
    max-height: 600px;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    background-color: #ffffff;
    color: #111433;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: translateY(-330px);
    -moz-transform: translateY(-330px);
    -ms-transform: translateY(-330px);
    -o-transform: translateY(-330px);
    transform: translateY(-330px);
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
    padding-top: 15px;
  }

  .dropdown-menu .close {
    /* on desktop - hide title and 'X' */
    display: none;
  }
  .dropdown-menu.is-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity 0.3s 0s, visibility 0.3s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0.3s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0.3s 0s, transform 0.3s 0s;
  }
}
@media only screen and (min-width: 1024px) {
  .dropdown-menu {
    left: calc(45% - 815px / 2);
  }
}
@media only screen and (min-width: 1500px) {
  .dropdown-menu {
    left: calc(40% - 815px / 2);
  }
}
@media only screen and (min-width: 1900px) {
  .dropdown-menu {
    left: calc(36% - 807px / 2);
  }
}
.service-group,
.service-group .nested-dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  padding-top: 24px;
  padding-bottom: 12px;
  z-index: 100;
}
.service-group a,
.service-group .nested-dropdown a {
  display: block;
  color: rgb(72, 72, 72);
  /* truncate text with ellipsis if too long */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* border-top-width: 1px;
  border-color: #242643;
  border-style: solid; */
}
.service-group a:hover {
  color: rgb(126, 126, 126);
}

.service-group li:first-of-type > a,
.service-group .nested-dropdown li:first-of-type > a {
  border-top-width: 0;
}
.service-group li:last-of-type > a,
.service-group .nested-dropdown li:last-of-type > a {
  border-bottom-width: 1px;
  padding-bottom: 5px;
}

.service-group a {
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.service-group.is-hidden,
.service-group .nested-dropdown.is-hidden {
  /* push the secondary dropdown items to the right */
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
.service-group.is-hidden > li > a,
.service-group .nested-dropdown.is-hidden > li > a {
  /* lower down the dropdown items opacity - when secondary dropdown slides in or for dropdown items hidden on the right */
  opacity: 0;
}
.is-active .service-group,
.is-active .service-group .nested-dropdown {
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 1023px) {
  .service-group li:last-of-type > a,
  .service-group .nested-dropdown li:last-of-type > a {
    border-bottom-width: 1px;
    padding-bottom: 70px;
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .service-group,
  .service-group .nested-dropdown {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .service-group,
  .service-group .nested-dropdown {
    padding-top: 0;
    overflow: visible;
    position: relative;
  }

  .service-group.is-hidden > li > a,
  .service-group .nested-dropdown.is-hidden > li > a {
    /* reset mobile style */
    opacity: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .service-group {
    /* reset mobile style */

    width: 100%;
  }

  .group-title {
    position: static;
    height: auto;
    width: 280px;
    float: left;
    width: 33.33333333%;
  }

  .service-group > li:last-of-type a {
    border-bottom: none;
  }

  .service-group .secondary-dropdown::after {
    clear: both;
    content: '';
    display: table;
  }

  .service-group .secondary-dropdown.is-hidden {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .service-group .secondary-dropdown.fade-in {
    /* animate secondary dropdown items when hovering over of of the .service-group list items */
    -webkit-animation: cd-fade-in 0.2s;
    -moz-animation: cd-fade-in 0.2s;
    animation: cd-fade-in 0.2s;
  }
  .service-group .secondary-dropdown.fade-out {
    /* animate secondary dropdown items when hovering over of of the .service-group list items */
    -webkit-animation: cd-fade-out 0.2s;
    -moz-animation: cd-fade-out 0.2s;
    animation: cd-fade-out 0.2s;
  }
  .service-group .secondary-dropdown > .go-back {
    display: none;
  }

  .service-group .secondary-dropdown .dropdown-menu-item,
  .service-group .secondary-dropdown a {
    border: none;
    font-weight: normal;
  }

  .service-group .secondary-dropdown > li {
    width: 100%;
    float: left;
  }
  .service-group .secondary-dropdown {
    overflow: hidden;
    width: 100%;
    padding-bottom: 25px;
  }
  .service-group .secondary-dropdown::before {
    /* this is the separation line in the middle of the .cd-secondary-dropdown element */
    position: absolute;
    content: '';
    top: 290px;
    left: 15px;
    height: 1px;
    width: 520px;
    background-color: #ebebeb;
  }
  .service-group .cd-secondary-dropdown > li > a {
    color: #f8f8f8;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 30px;
    height: 30px;
    pointer-events: none;
  }
  .service-group .cd-secondary-dropdown > li > a::after,
  .service-group .cd-secondary-dropdown > li > a::before {
    /* hide the arrow */
    display: none;
  }
  .service-group .cd-secondary-dropdown.move-out > li > a {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .service-group .cd-secondary-dropdown > li {
    margin: 20px 0;
    border-right-width: 1px;
    /* border-color: #ebebeb; */
    border-style: solid;
    padding: 0 30px;
    height: 250px;
  }
  .service-group .cd-secondary-dropdown > li:nth-of-type(2n) {
    border-right-width: 0;
  }
  .service-group .cd-secondary-dropdown > li > ul {
    /* reset mobile style */
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    position: relative;
    height: auto;
  }
  .service-group .cd-secondary-dropdown > li > ul > .go-back {
    display: none;
  }
  .service-group .cd-secondary-dropdown a {
    line-height: 25px;
    height: 25px;
    font-size: 1.3rem;
    padding-left: 0;
  }
  .no-touch .service-group .cd-secondary-dropdown a:hover {
    color: #f8f8f8;
  }
  .service-group .secondary-dropdown ul {
    padding-bottom: 25px;
    overflow: hidden;
    height: auto;
  }
  .service-group .secondary-dropdown .go-back a {
    padding-left: 20px;
    color: transparent;
  }
  .no-touch .service-group .secondary-dropdown .go-back a:hover {
    color: transparent;
  }
  .service-group .secondary-dropdown .go-back a::before,
  .service-group .secondary-dropdown .go-back a::after {
    left: 0;
  }
  /* .service-group > .group-title > ul {
    visibility: hidden;
  }
  .service-group > .group-title > ul.is-active {
    /* when hover over .service-group items - show subnavigation */
  /*  visibility: visible;
  }*/
  /* if .cd-secondary-dropdown is visible - show also subnavigation */
  /*  .service-group > .group-title > .cd-secondary-dropdown.is-active > li > ul {
    visibility: visible;
  } */
  .service-group > .group-title > a.is-active {
    /* hover effect for .service-group items with subnavigation */
    box-shadow: inset 2px 0 0 #f8f8f8;
    color: #f8f8f8;
  }
  .service-group > .group-title > a.is-active::before,
  .service-group > .group-title > a.is-active::after {
    background: #f8f8f8;
  }
  .open-to-left .service-group > .group-title > a.is-active {
    box-shadow: inset -2px 0 0 #f8f8f8;
  }
}

@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
@-moz-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
@keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}

.group-title > a,
.go-back a {
  position: relative;
}
.group-title > a::before,
.group-title > a::after,
.go-back a::before,
.go-back a::after {
  /* arrow icon in CSS - for element with nested unordered lists */
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: inline-block;
  height: 2px;
  width: 10px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.group-title > a::before,
.go-back a::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.group-title > a::after,
.go-back a::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@media only screen and (min-width: 1024px) {
  .group-title > a::before,
  .group-title > a::after,
  .go-back a::before,
  .go-back a::after {
    background: #d42727;
    display: none;
  }
}

.group-title > a {
  padding-right: 40px;
}
.group-title > a::before,
.group-title > a::after {
  /* arrow goes on the right side - children navigation */
  right: 20px;
  -webkit-transform-origin: 9px 50%;
  -moz-transform-origin: 9px 50%;
  -ms-transform-origin: 9px 50%;
  -o-transform-origin: 9px 50%;
  transform-origin: 9px 50%;
}

.service-group .go-back a {
  padding-left: 32px;
}
.service-group .go-back a::before,
.service-group .go-back a::after {
  /* arrow goes on the left side - go back button */
  left: 20px;
  background: rgb(72, 72, 72);
  -webkit-transform-origin: 1px 50%;
  -moz-transform-origin: 1px 50%;
  -ms-transform-origin: 1px 50%;
  -o-transform-origin: 1px 50%;
  transform-origin: 1px 50%;
}

.dropdown-menu h2 {
  position: relative;
  display: block;
  z-index: 1;
  color: #111433;
  font-size: 1rem;
  padding-left: 1em;
  background-color: #ffffff;
}

.dropdown-menu.is-active {
  transform: translateY(65px);
}

@media (min-width: 1024px) {
  .dropdown-menu h2 {
    display: none;
  }
  .dropdown-menu.is-hidden {
    transform: translateX(100px);
    transform: translateY(-300px);
  }
  .dropdown-menu.is-active {
    transform: translateX(0px);
    transform: translateY(0px);
  }
  .service-group.is-hidden,
  .service-group .nested-dropdown.is-hidden {
    transform: translateX(0px);
  }
}

.no-js .dropdown-menu-wrapper:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.no-js .dropdown-menu-wrapper .close {
  display: none;
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/source-sans-pro-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v18-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/source-sans-pro-v18-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/source-sans-pro-v18-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/source-sans-pro-v18-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v18-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/source-sans-pro-v18-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-300italic.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/source-sans-pro-v18-latin-300italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/source-sans-pro-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v18-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/source-sans-pro-v18-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/source-sans-pro-v18-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/source-sans-pro-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v18-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/source-sans-pro-v18-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/source-sans-pro-v18-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/source-sans-pro-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('/fonts/source-sans-pro-v18-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('/fonts/source-sans-pro-v18-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/fonts/source-sans-pro-v18-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('/fonts/source-sans-pro-v18-latin-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
  font-display: swap;
}
@import 'fontawesomestyles.css';
@tailwind utilities;
